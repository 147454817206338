import React, {Fragment} from 'react';
import Helmet from 'react-helmet';
import GlobalStyle from '../styles/GlobalStyle';
import {Container} from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import clearLogo from '../assets/images/clear-logo.png';

let pageTitle = 'Legal Notice | Libretto';
let metaTags = [
	{name: 'description', content: 'Legal Notice'},
	{name: 'keywords', content: 'Legal Notice for Libretto'},
	{property: 'og:title', pageTitle},
	{property: 'og:image', content: clearLogo},
	{property: 'twitter:image', content: clearLogo}
];
/* eslint-disable max-len */
const LegalNotice = () =>
	<Fragment>
		<Helmet
			title={pageTitle}
			meta={metaTags}
		/>
		<GlobalStyle />
		<Header generic />
		<Container>
			<h2>Legal Notice</h2>
			<h3>Updated: July 1, 2019</h3>
			<p>Welcome and thank you for visiting <a
				href="https://libretto.io/"
				rel="noopener noreferrer"
				target="_blank">https://libretto.io</a>{' '}
				(the &quot;Site&quot;), a website operated by Libretto LLC (&quot;Libretto&quot;). By accessing or using the Site, you as the user (&quot;you&quot;) agree to be bound by the following terms and conditions (these &quot;Terms&quot;).
			</p>
			<p>
				Please read these Terms carefully before using the Site. These Terms are a legally binding agreement between you and Libretto. These Terms do not purport to supersede any legally binding agreements between you and Libretto, which are independent from your use of the Site. Minors and children should not use the Site. BY ACCESSING OR USING THE SITE, YOU REPRESENT AND WARRANT THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO THESE TERMS, INCLUDING THEIR DISCLAIMERS AND LIMITATIONS OF LIABILITY, AND THAT YOU HAVE THE LEGAL CAPACITY TO ENTER INTO A BINDING AGREEMENT. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, DO NOT USE THE SITE.
			</p>
			<h4>1. Use of this Site.</h4>
			<p>Subject to your ongoing compliance with these Terms, Libretto hereby grants you a limited, personal, revocable, nonexclusive, nonassignable, nonsublicenseable license and right to access the Site with a generally available web browser to view the Content (defined below) and use the Site. Any other use of the Site is strictly prohibited and a violation of these Terms. Libretto and its licensors reserve all rights not expressly granted in these Terms, including, without limitation, rights of title, ownership, intellectual property and all other rights and interests in the Site, the Content and all related items.</p>

			<h4>2. Restrictions on Use.</h4>
			<p>Your use of the Site, including all features and functionalities associated therein, shall be in accordance with all applicable laws, rules and regulations, or other restrictions on use of the Site or its Content. You shall comply with these Terms and shall not: (i) archive, download, reproduce, distribute, modify, display, perform, publish, license, create derivative works of, offer for sale, or use, any Content or information contained in or obtained from or through the Site; (ii) delete the copyright or other proprietary rights notices from the Site or any Content; (iii) circumvent, remove, alter, deactivate, degrade or thwart any of the content or other protections enabled on the Site; (iv) use any robot, spider, scraper or other automated means to access the Site; (v) decompile, reverse engineer or disassemble any software or other products or processes accessible through the Site; (vi) insert any code or product or manipulate the Site in any way; (vii) use any data mining, data gathering or extraction method; (viii) upload, post, e-mail or otherwise send or transmit any material designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment associated with the Site, including any software viruses or any other computer code, files or programs; (ix) remove, modify, disable, block, impair, or obscure any advertising in connection with the Site; (x) use or encourage the use of the Site or its Content for any illegal purpose, or in violation of any local, state, national, or international law, including, without limitation, laws governing intellectual property and other proprietary rights, data protection and privacy; or (xi) assist, encourage or enable others to do any of the preceding prohibited activities.</p>

			<h4>3. Cessation of Service; Termination.</h4>
			<p>Libretto reserves the right to modify, terminate and suspend the operation of the Site, as well as the provision of any or all products, services or Content via the Site, without notice or liability, at any time and for any or no reason in its sole and absolute discretion. The rights granted to you herein shall terminate immediately upon any violation of these Terms, without prejudice to any other rights or remedies of Libretto. Libretto reserves the right to terminate your access to and use of the Site at any time for any reason without notice or liability to you or any third party.</p>

			<h4>4. Privacy.</h4>
			<p>The <a
				href="https://libretto.io/privacy-policy/"
				rel="noopener noreferrer"
				target="_blank">Libretto Privacy Policy</a>, which is available on the Site, governs any information you submit via the Site and is incorporated by reference into these Terms. All other policies posted on the Site by Libretto are also incorporated into these Terms by reference. Each reference to these Terms herein includes reference to the Privacy Policy and all other policies posted on the Site. You shall comply with these policies when accessing or using the Site.</p>

			<h4>5. Security.</h4>
			<p>Certain areas of the Site are password protected and are accessible only to those who have a valid Libretto-issued password. You shall not attempt to access any password-protected areas of the Site without authorization. In the event that you have been issued such a password, you acknowledge and agree that Libretto has granted you limited access to specific files pertaining to you, and you shall not attempt to access other files or Content. Persons who have access to the password-protected area of the Site are responsible for maintaining the confidentiality of any account information, user names, and/or security questions and answers that they may use to access any page or feature of the password-protected area, and for logging off any protected areas of the Site. Accordingly, you shall take steps to protect the confidentiality of your password, and you shall notify Libretto immediately if you become aware of any disclosure, loss, theft or unauthorized use of your password. Libretto makes no warranty, express or implied, regarding the security of the Site, including with respect to the ability of unauthorized persons to intercept or access information transmitted by or to you through the Site.</p>

			<h4>6. Intellectual Property.</h4>
			<p>All content included or available on the Site, including, but not limited to, all text, graphics, videos, audio recordings, software, algorithms, artwork, interfaces, photographs, logos, icons, and images and the selection and arrangement thereof along with any enhancements to or derivative works thereto, and the features and functionality of the Site (collectively “Content”) is the exclusive property of Libretto or its licensors and is protected by U.S. and international laws. Any use of materials on the Site, including reproduction for purposes other than those expressly permitted herein, any modification, distribution, or replication, any form of data extraction or data mining, or other commercial exploitation of any kind, without prior written permission of an authorized officer of Libretto is strictly prohibited. You shall not use any robot, spider, or other automatic device, or manual process to monitor or copy Libretto’s webpages or the Content contained therein. You shall not “mirror” or “frame” any Content or the Site itself, in whole or in part, without Libretto’s express written permission. Any unauthorized use of the Content may violate copyright laws, trademark laws, the laws of privacy and publicity and/or communications regulations and statutes. All rights not expressly granted are hereby reserved.</p>
			<p>LIBRETTO&trade; is a proprietary service mark of Libretto. Libretto’s marks shall not be used in connection with any products or services that are not provided by Libretto, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Libretto. Other trademarks displayed on the Site are the trademarks of their respective owners, and constitute neither an endorsement nor a recommendation.</p>

			<h4>7. Third-Party Services.</h4>
			<p>The Site may include links to other websites on the Internet that are owned and operated by third parties. In addition, you may encounter third-party applications, including, without limitation, websites, widgets, software or software utilities, that interact with the Site. You acknowledge and agree that Libretto is not responsible for the availability of, or the content located on or through, any third-party website or application. The selection or omission of links is not intended to endorse any particular companies, products or services. If you decide to access any of the third-party websites or applications linked to the Site, you do so entirely at your own risk. You should contact the website administrator or webmaster for those third-party websites or applications if you have any concerns regarding such links or the content located on such websites or within such application. Your use of third-party websites and applications may be subject to third-party terms of use, privacy policies and license terms, which all users are encouraged to review.</p>

			<h4>8. Disclaimer of Warranties.</h4>
			<p>THE SITE, INCLUDING ALL CONTENT THEREIN, IS PROVIDED BY LIBRETTO ON AN “AS IS” AND AN “AS AVAILABLE” BASIS, WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND. To the fullest extent permitted by applicable law, Libretto disclaims all representations and warranties, express, implied, statutory or otherwise, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. Specifically, but without limitation, Libretto does not represent or warrant that: (i) the Content, information or results on or provided through the Site are correct, accurate, or reliable; (ii) access to and use of the Site shall be uninterrupted or error-free; or (iii) the Site or the server that makes it available is free of viruses or other harmful components or defects or that defects shall be corrected.</p>

			<h4>9. Limitation of Liability.</h4>
			<p>YOUR USE OF AND ACCESS TO THE SITE AND ANY CONTENT IS AT YOUR OWN RISK. IN NO EVENT SHALL LIBRETTO OR ANY OF ITS PREDECESSORS, SUCCESSORS, PARENTS, SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, SHAREHOLDERS, INVESTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, ATTORNEYS AND THEIR RESPECTIVE HEIRS, SUCCESSORS AND ASSIGNS (THE “LIBRETTO PARTIES”) BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR OTHER DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE ACCESS TO OR USE OF THE SITE, ANY DELAY IN OR INABILITY TO USE THE SITE, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS AND SERVICES ADVERTISED IN OR OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, EVEN IF THE LIBRETTO PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. THIS WAIVER APPLIES, WITHOUT LIMITATION, TO ANY DAMAGES OR INJURY ARISING FROM ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, FILE CORRUPTION, COMMUNICATION-LINE FAILURE, NETWORK OR SYSTEM OUTAGE, OR THEFT, DESTRUCTION, UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF ANY RECORD.</p>
			<p>NOTWITHSTANDING THE FOREGOING, IF FOR ANY REASON ALL OR ANY PART OF THE ABOVE LIMITATION OF LIABILITY IS HELD UNENFORCEABLE, IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE LIBRETTO PARTIES, UNDER OR IN CONNECTION WITH THESE TERMS, OR ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE ACCESS TO OR USE OF THE SITE, ANY DELAY IN OR INABILITY TO USE THE SITE, OR FOR ANY INFORMATION, CONTENT, SOFTWARE, PRODUCTS AND SERVICES ADVERTISED IN OR OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, EXCEED THE FIFTY U.S. DOLLARS ($50.00).</p>
			<p>YOU ACKNOWLEDGE THAT, ABSENT YOUR AGREEMENT TO THIS LIMITATION OF LIABILITY, LIBRETTO WOULD NOT ALLOW YOU TO ACCESS OR USE THE SITE. SOME JURISDICTIONS DO NOT ALLOW FOR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, THEREFORE SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY. </p>

			<h4>10. Indemnification.</h4>
			<p>You shall indemnify and hold harmless the Libretto Parties from and against any and all claims, actions, suits, losses, liabilities, expenses, damages, and costs, including reasonable attorneys’ fees and costs, made by any third party due to or arising out of: (i) your access to or use of the Site and its Content; (ii) your actual or alleged breach of these Terms; or (iii) any infringement by you or any other user of the Site using your computer of any intellectual property or any other right of any person or entity. You shall cooperate as fully as reasonably required in the defense of any such claim. Libretto reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification by you. Each of the Libretto Parties is an express third-party beneficiary of this indemnification provision, with full rights to enforce its terms.</p>

			<h4>11. Governing Law; Jurisdiction.</h4>
			<p>These Terms shall be governed by and construed in accordance with the laws of the State of California, United States of America, without giving effect to its choice of law rules. Any action, claim or proceeding arising out of or relating to the Site or these Terms shall be brought in the appropriate court located in Los Angeles, California. You hereby consent to the exclusive jurisdiction of such court in any such action, claim or proceeding and submit yourself to such jurisdiction.</p>

			<h4>12. Entire Agreement.</h4>
			<p>These Terms contain the entire agreement between the parties with respect to the subject matter hereof, and supersede all prior and contemporaneous representations, promises, agreements and understandings, whether oral or written, between the parties concerning the subject matter hereof. You may from time to time enter into binding legal agreements relating to certain products or services available through the Site, which may have terms that are different from those of these Terms. In the event of any inconsistency, the terms of such other agreement shall control with respect to such products or services.</p>

			<h4>13. Waiver; Severability.</h4>
			<p>Any waiver of any provision of these Terms shall be effective only if in writing and signed by Libretto. Any failure to enforce any provision of these Terms shall not constitute a waiver of a future breach of that or any other provision of these Terms. If any provision of these Terms is deemed to be invalid or unenforceable, the remaining provisions of these Terms shall be valid and binding and of like effect as though such provision were not included.</p>

			<h4>14. Amendment.</h4>
			<p>Libretto may, in its sole discretion, revise these Terms at any time with or without additional notice to you. Such revised Terms shall be effective when posted on the Site. You should revisit these Terms on a regular basis, as revised versions will be binding upon you. You understand and agree that your continued access to or use of the Site after the effective date of changes to these Terms indicates your acceptance of such revisions.</p>
		</Container>
		<Footer />
	</Fragment>;

/* eslint-enable max-len */
export default LegalNotice;
